.carousel {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.carousel-item-wrapper {
  white-space: nowrap;
  transition: transform 0.3s;
}

.carousel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
