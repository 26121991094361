/* Overstyr stien til fontene (rammeverkspesifikt) */
@use "sass:map";
@use "~@oslokommune/punkt-css/dist/scss/abstracts/variables" with (
  $font-path: "~@oslokommune/punkt-assets/dist"
);

/* Bruk designsystemet */
@use "~@oslokommune/punkt-css/dist/scss/pkt";

/* Legg merke til at breakpoints hentes inn uten namespace: */
@use "@oslokommune/punkt-css/dist/scss/abstracts/mixins/breakpoints" as *;
@use "@oslokommune/punkt-css/dist/scss/abstracts/mixins/typography";

h1 {
  @include typography.get-text("pkt-txt-54");
  margin-bottom: map.get(variables.$spacing, "size-30");

  @include bp("tablet-up") {
    @include typography.get-text("pkt-txt-36");
  }
}

h2 {
  @include typography.get-text("pkt-txt-36");
  margin-bottom: map.get(variables.$spacing, "size-20");

  @include bp("tablet-up") {
    @include typography.get-text("pkt-txt-24");
  }
}

h3 {
  @include typography.get-text("pkt-txt-24");
  margin-bottom: map.get(variables.$spacing, "size-20");

  @include bp("tablet-up") {
    @include typography.get-text("pkt-txt-20");
  }
}

p,
span {
  @include typography.get-text("pkt-txt-20");

  &:not(:last-child) {
    margin-bottom: map.get(variables.$spacing, "size-20");
  }

  @include bp("tablet-up") {
    @include typography.get-text("pkt-txt-16");
  }
}

main {
  min-height: 100%;
  min-width: 100%;
}
