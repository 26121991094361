@import url("https://ukeweb-styleguide-cdn.s3.eu-central-1.amazonaws.com/0.99.93/osg.css"); /* Version 0.99.93 og ikke høgre då denne ikke har nav arrows for buttons */

.app {
  width: 100%;
  height: 100%;
}

header {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #d3d3d3;
}

.header-logo-and-title {
  display: flex;
  gap: 20px;
  padding: 20px 20px;
  align-items: center;
}

body {
  font-family: "Oslo Sans", "sans-serif", "Lato", "Helvetica Neue", "Helvetica",
    "Arial";
  text-align: left;
  font-size: 1.125rem;
  background-color: white;
  color: #2a2859;
}

.app {
  display: flex;
  justify-content: center;
}

.app > * {
  max-width: 1024px;
}

@media only screen and (max-width: 1200px) {
  .app {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media only screen and (max-width: 500px) {
  .app {
    padding-right: 13px;
    padding-left: 13px;
  }

  .heading {
    visibility: hidden;
  }
}

.gebyrinfo-small-screen label {
  font-weight: 700;
}

.gebyrinfo-small-screen li {
  padding-top: 10px;
}

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
